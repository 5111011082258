enum MemberGroup {
  InternalBetaTester = 'internal_beta_tester',
  ExternalBetaTester = 'general-external-beta',
  ExternalWebBetaTester = 'external_web_beta',
  DigitalInternal = 'digital_internal',
  TagsViralLaunch = 'tags_viral_launch',
  StacksBeta = 'stacked_classes_internal_beta',
  InternalEcommTester = 'internal_ecomm_testing',
  WebTVInternal = 'webtv_internal',
}

export default MemberGroup;
