export const APP_ONE_30D_TRIAL_SKU = 'pl-a-sub-01-30d-ft';
export const CP_MTM_SKU = 'pl-a-sub-01-cp';
export const STUDENT_MTM_SKU = 'pl-a-sub-01-sp';
export const CP_TRIAL_SKU = 'pl-a-sub-01-60d-ft';
export const APP_ONE_GP_TRIAL_SKU = 'pl-a-sub-01-gp-60d-ft';
export const APP_PLUS_GP_TRIAL_SKU = 'pl-a-sub-02-gp-60d-ft';
export const APP_ONE_MTM_SKU = 'pl-a-sub-01';
export const APP_PLUS_MTM_SKU = 'pl-a-sub-02';
export const APP_ONE_60D_PARTNERSHIP_TRIAL_SKU = 'pl-a-sub-01-p-60d-ft';
export const APP_PLUS_60D_PARTNERSHIP_TRIAL_SKU = 'pl-a-sub-02-p-60d-ft';
export const LULULEMON_APP_ONE_60D_TRIAL = APP_ONE_60D_PARTNERSHIP_TRIAL_SKU;
export const LULULEMON_APP_PLUS_60D_TRIAL = APP_PLUS_60D_PARTNERSHIP_TRIAL_SKU;
export const HYATT_APP_ONE_60D_TRIAL = APP_ONE_60D_PARTNERSHIP_TRIAL_SKU;
export const HYATT_APP_PLUS_60D_TRIAL = APP_PLUS_60D_PARTNERSHIP_TRIAL_SKU;
