import { isProdEnv, isProdTestEnv } from '@peloton/env/models';

const getContentfulAccessToken = (appName: string = 'www'): string => {
  const PROD = process.env.CONTENTFUL_WWW_PROD_TOKEN;
  const PREVIEW = process.env.CONTENTFUL_WWW_PREVIEW_TOKEN;

  if (PREVIEW && !isProdEnv(appName) && !isProdTestEnv(appName)) {
    return PREVIEW;
  }

  if (!PROD) {
    throw new Error('CONTENTFUL_WWW_PROD_TOKEN missing');
  }

  return PROD;
};

export const toContentfulAccessToken = getContentfulAccessToken;

export const CONTENTFUL_TOKEN = getContentfulAccessToken();
