type BaseContentfulRecord = {
  __typename: string;
  key?: string;
};

// dataIds of null represent records that will use Apollo's default key
type DataId = string | null;

export default ((i = 0) => ({ __typename, key }: BaseContentfulRecord): DataId => {
  if (__typename === 'Asset') return null;

  return `${__typename}:${key || i++}`;
})();
