import { toApiLink, toAccountLink } from '@peloton/external-links';

export const accountVatInvoicePdf = (orderId: string) =>
  toApiLink(`/ecomm/order/${orderId}/vat_invoice`);

export const SUBS_ROUTE = '/subscriptions';
export const MYMEMBERSHIP_ROUTE = '/mymembership';
export const ONE_WELLNESS_ROUTE = '/benefits/corporate';
export const FREE_SUB_ID = 'app-free';

export const orderHistoryAccount = toAccountLink('/orderhistory');
export const myMembershipAccount = toAccountLink(MYMEMBERSHIP_ROUTE);

export const toSubscriptionsUrl = () => `${MYMEMBERSHIP_ROUTE}${SUBS_ROUTE}`;

export const toSubDetailsUrl = (id: string = ':id') => `${toSubscriptionsUrl()}/${id}`;

export const toSwitchPlanUrl = (id: string = ':id') =>
  `${toSubDetailsUrl(id)}/switch-plan`;

export const to1wSwitchPlanUrl = (id: string = ':id') =>
  `${ONE_WELLNESS_ROUTE}/switch-plan/${id}`;

export const toUpgradePlanUrl = (id: string = ':id') =>
  `${toSubDetailsUrl(id)}/upgrade-plan`;

export const to1wUpgradePlanUrl = () => `${ONE_WELLNESS_ROUTE}/plan-checkout`;

export const toUpgradeFreeUrl = () => toSwitchPlanUrl(FREE_SUB_ID);

export const toSwitchBillingUrl = (id: string = ':id') =>
  `${toSubDetailsUrl(id)}/switch-billing`;

export const upgradePlanAccount = (id: string = ':id') =>
  toAccountLink(toUpgradePlanUrl(id));

export const switchPlanAccount = (id: string = ':id') =>
  toAccountLink(toSwitchPlanUrl(id));

export const switchBillingAccount = (id: string = ':id') =>
  toAccountLink(toSwitchBillingUrl(id));

export const upgrade1wFreeAccount = () => toAccountLink(to1wUpgradePlanUrl());
export const switch1wPlanAccount = (id: string = ':id') =>
  toAccountLink(to1wSwitchPlanUrl(id));

export const upgradeFreeAccount = () => toAccountLink(toUpgradeFreeUrl());
