/// <reference path="../../../node_modules/@types/segment-analytics/index.d.ts" />

import type { EventType, Identity, Group, Load, Page } from './types';

export interface AnalyticsInterface {
  track<T extends object>(event: EventType<T>): void;
  identify<T extends object>(payload: Identity<T>): void;
  group<T extends object>(group: Group<T>): void;
  reset(): void;
  load(load: Load): void;
  page<T extends object>(page: Page<T>): void;
  ready(callback: () => void): void;
  anonymousId(callback: (anonymousId: string) => void): void;
}

/**
 * Lazy-init `analytics` and call through, or throw if window.analytics object is not found
 *
 * @param {string} fn The SegmentAnalytics function to call
 * @param {any[]} args Arguments to pass along
 * @returns Result of calling through
 * @throws If Segment is not yet loaded (checks `window.analytics`)
 */
const initAndCallOrThrow = (fn: string, args: any) => {
  const winAnalytics =
    typeof window === 'undefined'
      ? false
      : ((window.analytics as unknown) as SegmentAnalytics.AnalyticsJS);
  if (winAnalytics) {
    setAnalytics(winAnalytics);
    return winAnalytics[fn].apply(window, args);
  } else {
    throw Error(
      `Attempted to call ${fn}() in analytics before it is initialized, and no window.analytics exists.`,
    );
  }
};

/**
 * App wide analytics instance.
 */
let instance: AnalyticsInterface | any = {
  track: (...args: any[]) => initAndCallOrThrow('track', args),
  identify: (...args: any[]) => initAndCallOrThrow('identify', args),
  group: (...args: any[]) => initAndCallOrThrow('group', args),
  reset: (...args: any[]) => initAndCallOrThrow('reset', args),
  load: (...args: any[]) => initAndCallOrThrow('load', args),
  page: (...args: any[]) => initAndCallOrThrow('page', args),
  ready: (...args: any[]) => initAndCallOrThrow('ready', args),
  anonymousId: (...args: any[]) => initAndCallOrThrow('anonymousId', args),
};

export const setAnalytics = (
  analytics: AnalyticsInterface | SegmentAnalytics.AnalyticsJS,
) => (instance = analytics);

export const identify = <T extends object>(payload: Identity<T>) => {
  instance.identify(payload);
};

export const track = <T extends object>(event: EventType<T>) => {
  return instance.track(event);
};

export const group = <T extends object>(event: Group<T>) => {
  instance.group(event);
};

export const reset = () => {
  instance.reset();
};

export const load = (config: Load, analytics?: AnalyticsInterface) => {
  if (analytics) {
    instance = analytics;
  }
  instance.load(config);
};

export const page = <T extends object>(event: Page<T>) => {
  instance.page(event);
};

export const anonymousId = (callback: (anonymousId: string) => void) => {
  instance.anonymousId(callback);
};

export const ready: AnalyticsInterface['ready'] = cb => {
  instance?.ready(cb);
};
