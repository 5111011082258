import challengePage from '@ecomm/pg-digital-checkout/components/ChallengeInvitationView/content';
import teamPage from '@ecomm/pg-digital-checkout/components/TeamInvitationView/content';
import appMembership, { appMembershipVariation } from '../app-membership/app-membership';
import apparelPDP from '../apparel-pdp/apparelPDP';
import apparelPLP from '../apparel-plp/apparelPLP';
import apparelQV from '../apparel-qv/apparelQV';
import apparel from '../apparel/apparel';
import cart from '../cart/cart';
import checkout from '../checkout/checkout';
import financing from '../financing/financing';
import footer from '../footer/footer';
import gifting from '../gifting/gifting';
import membership from '../membership/membership';
import nav from '../nav/nav';
import cartOtd from '../otd/cartOtd';
import privacyPolicyUpdates from '../privacy-policy-updates/privacyPolicyUpdates';
import productRecommendations from '../product-recommendations/product-recommendations';
import prospectReferralProductRecommendations from '../prospect-referral-product-recommendations/prospect-referral-product-recommendations';
import sso from '../sso/sso';
import switchPlan from '../switch-plan/switch-plan';
import type ContentAggregate from './ContentAggregate';

type IdToConfigMap = {
  [K: string]: ContentAggregate;
};

export const globalEntriesConfig: IdToConfigMap = {
  [cart.entryId]: cart,
  [cartOtd.entryId]: cartOtd,
  [footer.entryId]: footer,
  [nav.entryId]: nav,
  [productRecommendations.entryId]: productRecommendations,
  [financing.entryId]: financing,
  [apparelPDP.entryId]: apparelPDP,
  [prospectReferralProductRecommendations.entryId]: prospectReferralProductRecommendations,
  [apparelPLP.entryId]: apparelPLP,
  [apparelQV.entryId]: apparelQV,
  [apparel.entryId]: apparel,
  [membership.entryId]: membership,
  [switchPlan.entryId]: switchPlan,
  [appMembership.entryId]: appMembership,
  [appMembershipVariation.entryId]: appMembershipVariation,
  [gifting.entryId]: gifting,
  [checkout.entryId]: checkout,
  [privacyPolicyUpdates.entryId]: privacyPolicyUpdates,
  [sso.entryId]: sso,
  [challengePage.entryId]: challengePage,
  [teamPage.entryId]: teamPage,
};
