import { replace, push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { LOCAL_STORAGE_CUSTOM_EVENT_TYPE } from '@peloton/hooks/shared/useLocalStorage';
import { redirect as externalRedirect } from '@peloton/navigation';
import { getAnnuallyBilledSubscription } from '@peloton/subscription-plans';
import { AddressType, ErrorCode } from '@ecomm/checkout/models';
import {
  getAttribution,
  getDoesUserExist,
  getVerification,
  hasCheckoutErrors,
  hasAcceptedTermsError,
  hasCheckoutWithNoPaymentErrors,
} from '@ecomm/checkout/redux';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import type { ID, Token } from '@ecomm/models';
import { PaymentMethod } from '@ecomm/models';
import { createSetupIntentSaga } from '@ecomm/payment';
import type {
  PaymentsOverrideConfiguration,
  SuccessRedirectOverride,
} from '@ecomm/quick-checkout/models';
import { getOverridePurchaseAnnual } from '@ecomm/quick-checkout/redux';
import { getExistingSubData } from '@ecomm/user-subscriptions/selectors';
import { hasClaimBenefitsError } from '@onewellness/models';
import { LOOKUP_PATH as onewellnessErrorPath } from '@onewellness/routes';
import { digitalCheckout, quickCheckout } from '../api';
import { CheckoutType, toPayment } from '../models';
import type { CheckoutRequestAction } from '../redux';
import {
  ActionTypes,
  failCheckout,
  getDigitalCheckoutData,
  getQuickCheckoutData,
  getPostalCode,
} from '../redux';
import type { AddedGiftCardData } from '../redux/giftCards';

export const toSelector = (checkoutType: CheckoutType) =>
  checkoutType === CheckoutType.Digital ? getDigitalCheckoutData : getQuickCheckoutData;

export const toCheckout = (checkoutType: CheckoutType) =>
  checkoutType === CheckoutType.Digital ? digitalCheckout : quickCheckout;

export const toDeliveryUrl = (id: ID, promotion: ID | undefined) =>
  promotion ? `/delivery/${id}/status?promo=${promotion}` : `/delivery/${id}/status`;

export const toAnnualUpgradeUrl = () => '/annual-billing-upgrade?cancel_monthly=true';

export const checkoutSaga = function* (
  checkoutType: CheckoutType,
  token: Token,
  productOptionId: ID,
  paymentsOverride: PaymentsOverrideConfiguration,
  promotion?: ID,
  successRedirectOverride?: SuccessRedirectOverride,
  giftCardPayments?: AddedGiftCardData[],
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const selector = yield call(toSelector, checkoutType);
  const checkout = yield call(toCheckout, checkoutType);

  const data = yield select(selector, { addressType: AddressType.Billing });
  const attribution = yield select(getAttribution);
  const verification = yield select(getVerification);

  const overridePurchaseAnnual = yield select(getOverridePurchaseAnnual);
  const annualSub = yield select(getAnnuallyBilledSubscription);

  const existingSubData = yield select(getExistingSubData);
  const hasExistingDigitalSub =
    existingSubData.hasExistingSub && !existingSubData.isOnlyPreDelivery;

  const checkoutProductOptionId = overridePurchaseAnnual
    ? annualSub?.id ?? productOptionId
    : productOptionId;

  // TODO: Select the price from the store and pass to toPayment
  let payments: Array<Record<string, string | number | undefined>> = [];

  if (giftCardPayments) {
    payments = giftCardPayments.map(p => ({
      amountInCents: 0,
      label: p.lastFour,
      paymentType: 'gift_card',
      paymentMethodToken: p.paymentMethodToken,
    }));
  }

  switch (token.kind) {
    case 'benefit_hub':
      payments.push({
        amountInCents: 0,
        label: 'Benefit Hub',
        paymentType: token.kind,
        benefit_hub_access_token: paymentsOverride.benefitHub?.accessToken,
      });
      break;
    case 'stripe_setup_intent':
      payments.push({
        amountInCents: 0,
        label: paymentsOverride.paymentMethod || PaymentMethod.CreditCard,
        paymentType: token.kind,
        setupIntentId: token.id,
      });
      break;
    case 'null_payment':
      payments.push({
        amountInCents: 0,
        label: 'Null Payment',
        paymentType: token.kind,
      });
      break;
    default:
      payments.push(yield call(toPayment, token));
  }

  try {
    const checkoutData = {
      ...data,
      productOptionId: checkoutProductOptionId,
      payments,
      onlyIncludes: ['id', 'external_id', 'user_id'],
      attribution,
      verification,
    };

    if (paymentsOverride.taxExempt) {
      checkoutData.taxExempt = true;
    }

    const response = yield call(checkout, client, checkoutData);

    if (response.accessToken) {
      yield call(
        [localStorage, 'setItem'],
        CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
        JSON.stringify(response.accessToken),
      );

      yield call(
        dispatchEvent,
        new StorageEvent(LOCAL_STORAGE_CUSTOM_EVENT_TYPE, {
          key: CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
        }),
      );
    }

    if (response.data.futureOrder) {
      yield call(
        externalRedirect,
        `/benefit-renewal?expirationDate=${response.data.futureOrder.expirationDate}&startDate=${response.data.futureOrder.startDate}`,
      );
    } else {
      const redirectToOrderStatus = yield select(
        getIsToggleActive('orderStatusForAppPurchases'),
      );

      const redirectToAnnualUpgrade = overridePurchaseAnnual && hasExistingDigitalSub;

      const confirmationQRCodeStepEnabled = yield select(
        getIsToggleActive('confirmation_qr_code'),
      );
      const isSSOEnabled = yield select(getIsToggleActive('sso_register'));
      const id =
        confirmationQRCodeStepEnabled || isSSOEnabled
          ? response.data.externalId || response.data.id
          : response.data.id;

      const defaultSuccessRedirect = redirectToOrderStatus
        ? redirectToAnnualUpgrade
          ? call(externalRedirect, toAnnualUpgradeUrl())
          : call(
              externalRedirect,
              toDeliveryUrl(response.data.externalId || response.data.id, promotion),
            )
        : put(push(`/checkout/success?order_id=${response.data.id}`));

      const userId = response.data.userId;
      const successRedirect = successRedirectOverride
        ? call(successRedirectOverride, id, promotion, { userId })
        : defaultSuccessRedirect;
      yield successRedirect;
    }
  } catch (error) {
    let reportedError;
    const { responseBody = {}, ...originalError } = error.originalError || {};

    const errorMessage =
      error.message === ErrorCode.Default ? responseBody.message : error.message;
    const errorId = `errors.${error.message}`;

    const codes = {
      errorCode: responseBody.errorCode,
      status: responseBody.status,
    };

    if (giftCardPayments && giftCardPayments.length > 0) {
      reportedError = new DomainError('Gift Card | Subscription Checkout failed', {
        ...responseBody,
        ...originalError,
      });
    } else {
      reportedError = new DomainError(
        `${checkoutType} checkout [${JSON.stringify(codes)}]: ${errorMessage}`,
        { ...responseBody, ...originalError },
      );
    }

    yield all([put(failCheckout(errorId)), put(reportError({ error: reportedError }))]);

    if (token.kind === 'benefit_hub' && hasClaimBenefitsError(error)) {
      yield put(replace(`${onewellnessErrorPath}?error=${errorId}`));
    }
  }
};

export const checkoutWithSetupIntentsSaga = function* ({
  payload: {
    product: productOptionId,
    checkoutType,
    paymentGateway,
    paymentsOverride,
    promotion,
    recaptchaToken,
    giftCardPayments,
    successRedirectOverride,
  },
}: CheckoutRequestAction): SagaIterator {
  try {
    const postalCode = yield select(getPostalCode, { addressType: AddressType.Billing });
    const setupIntent = recaptchaToken
      ? yield call(createSetupIntentSaga, {
          paymentGateway,
          paymentsOverride,
          postalCode,
          recaptchaToken,
        })
      : yield call(createSetupIntentSaga, {
          paymentGateway,
          paymentsOverride,
          postalCode,
        });

    yield call(
      checkoutSaga,
      checkoutType,
      { kind: 'stripe_setup_intent', id: setupIntent.id },
      productOptionId,
      paymentsOverride,
      promotion,
      successRedirectOverride,
      giftCardPayments,
    );
  } catch (error) {
    const errorId = `errors.${error.message}`;
    yield all([put(failCheckout(errorId)), put(reportError({ error }))]);
  }
};

export const validateSaga = function* (action: CheckoutRequestAction): SagaIterator {
  let hasErrors;
  const { paymentsDisabled, paymentMethod } = action.payload.paymentsOverride;

  if (paymentsDisabled) {
    hasErrors = yield select(hasCheckoutWithNoPaymentErrors);
  } else {
    hasErrors = yield select(hasCheckoutErrors, {
      addressType: AddressType.Billing,
      paymentMethod: paymentMethod || PaymentMethod.CreditCard,
    });
  }

  const userShouldLogin = yield select(getDoesUserExist);
  const hasNotAcceptedTerms = yield select(hasAcceptedTermsError);

  if (hasErrors || hasNotAcceptedTerms) {
    yield put(failCheckout('errors.incomplete'));
  } else if (userShouldLogin) {
    yield put(failCheckout('errors.needsLogin'));
  } else {
    if (paymentsDisabled) {
      const token: Token = action.payload.paymentsOverride.benefitHub
        ? { kind: 'benefit_hub', id: '' }
        : { kind: 'null_payment', id: '' };

      yield call(
        checkoutSaga,
        action.payload.checkoutType,
        token,
        action.payload.product,
        action.payload.paymentsOverride,
        action.payload.promotion,
        action.payload.successRedirectOverride,
      );
    } else {
      yield call(checkoutWithSetupIntentsSaga, action);
    }
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionTypes.CheckoutRequested, validateSaga);
};

export default watcherSaga;
