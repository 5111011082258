/**
 * These must match with the subdomain that the apps are
 * deployed to in production.
 *
 * They are used both for matching the app in production as
 * well as for matching the app in the sub-subdomain in UAT.
 */
export const APPS = [
  'account',
  'admin',
  'auth',
  'auth-stage',
  'bigclock',
  'genie',
  'members',
  'pos',
  'preorder',
  'studio',
  'tv',
  'uikit',
  'universal-logout',
  'upnext',
  'next-www',
  'www',
] as const;
