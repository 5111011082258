import type { AxiosRequestConfig } from 'axios';
import { identity, prop } from 'ramda';
import type { ClientDetails } from '@peloton/analytics/clientDetailsHeader';
import { toClientDetailsHeader } from '@peloton/analytics/clientDetailsHeader';
import type { Client, ApiErrors } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type { AccountForbiddenErrors } from '@peloton/auth/errors';
import type { AccountSubErrors } from '../errors';
import { handlePasswordChangeError, handleAccountApiError } from '../errors';
import { toUser, toSession } from '../mappers';
import type {
  CreateAccountCredentials,
  Credentials,
  User,
  ContractDocuments,
  ContractAgreement,
  Session,
} from '../models';
import type { ApiMeUser, ApiSession } from '../types';
import {
  setPasswordForNewUser,
  ErrorCode as SetNewUserPasswordErrorCode,
  toSetNewUserPasswordErrorCodeOrDefault,
} from './setNewUserPassword';

// TODO: break this file into concerns

export const toApiCredentials = ({ email, password }: Credentials): ApiCredentials => ({
  usernameOrEmail: email,
  password,
});

export const toCreateAccountPayload = ({
  username,
  email,
  password,
  allowMarketing,
  contract_agreements,
}: CreateAccountCredentials): ApiCreateAccountPayload => ({
  username,
  email,
  password,
  allowMarketing: allowMarketing,
  contract_agreements: contract_agreements || [],
});

// If opts is passed a string it is assumed it's an email
export const toUserExistsQuery = (opts: FetchUserExistsOpts) => ({
  params: typeof opts === 'string' ? { email: opts } : opts,
});

export const doesUserExist = ({ exists }: ApiUserExists): boolean => Boolean(exists);

export const isValid = ({ valid }: TokenValid): boolean => Boolean(valid);

export const resetSuccess = ({ success }: ResetSuccessful): boolean => Boolean(success);

type ApiCredentials = {
  usernameOrEmail: string;
  password: string;
};

type ApiCreateAccountPayload = {
  username: string | undefined;
  email: string;
  password: string;
  allowMarketing?: boolean;
  contract_agreements: ContractAgreement[];
};

type ApiUserExists = {
  exists: boolean;
};

type TokenValid = {
  valid: boolean;
};

type ResetSuccessful = {
  success: boolean;
};

type ApiDocs = {
  contractDocuments: ContractDocuments[];
};

const ME_URL = '/api/me';
const CREATE_ACCOUNT_URL = '/api/user';
const LOG_IN_URL = '/auth/login';
const LOG_OUT_URL = '/auth/logout';
const USER_EXISTS_URL = '/api/user/exists';
const FORGOT_PASSWORD = '/auth/forgot_password';
const CHANGE_PASSWORD = '/auth/change_password';
const DOCS = '/api/docs';
const VALIDATE_RESET_HASH = '/auth/validate_reset_hash';

// TODO: update for OAuth, are we sending OAuth header here?
export const fetchUser = (api: Client, config?: AxiosRequestConfig): Promise<ApiMeUser> =>
  api.get(ME_URL, config).then(pipeData(identity));

export const fetchAuthUser = (api: Client, config?: AxiosRequestConfig): Promise<User> =>
  fetchUser(api, config).then(toUser);

export const createAccount = <CreateAccountAnalyticsProps extends Record<string, any>>(
  api: Client,
  createAccountCredentials: CreateAccountCredentials,
  analytics: ClientDetails<CreateAccountAnalyticsProps>,
): Promise<string | void> =>
  api
    .post(
      CREATE_ACCOUNT_URL,
      toCreateAccountPayload(createAccountCredentials),
      toClientDetailsHeader(analytics),
    )
    .then(pipeData<any, string>(prop<string>('id')))
    .catch(handleAccountApiError);

export const logIn = <LoginAnalyticsProps extends Record<string, any>>(
  api: Client,
  credentials: Credentials,
  analytics: ClientDetails<LoginAnalyticsProps>,
  withPubsub?: boolean,
): Promise<Session | ApiErrors | AccountSubErrors | AccountForbiddenErrors> =>
  api
    .post(
      LOG_IN_URL,
      { ...toApiCredentials(credentials), withPubsub: !!withPubsub },
      toClientDetailsHeader(analytics),
    )
    .then(pipeData<ApiSession, Session>(toSession))
    .catch(handleAccountApiError);

export const logOut = <LogoutAnalyticsProps extends Record<string, any>>(
  api: Client,
  analytics: ClientDetails<LogoutAnalyticsProps>,
): Promise<boolean> =>
  api
    .post(LOG_OUT_URL, null, toClientDetailsHeader(analytics))
    .then(pipeData(() => true));

export const fetchUserExists = (
  api: Client,
  opts: FetchUserExistsOpts,
): Promise<boolean> =>
  api.get(USER_EXISTS_URL, toUserExistsQuery(opts)).then(pipeData(doesUserExist));

export const forgotPassword = (api: Client, email: string): Promise<string> =>
  api
    .post(FORGOT_PASSWORD, { email }, { meta: { skipErrorHandlers: true } } as any)
    .then(pipeData<any, string>(prop<string>('email')));

export const validateResetHash = (api: Client, resetHash: string): Promise<boolean> =>
  api.get(`${VALIDATE_RESET_HASH}/${resetHash}`).then(pipeData(isValid));

export const resetForgotPassword = (
  api: Client,
  resetHash: string,
  password: string,
): Promise<boolean> =>
  api.post(`${FORGOT_PASSWORD}/${resetHash}`, { password }).then(pipeData(resetSuccess));

export const changePassword = (api: Client, opts: ChangePwOpts): Promise<void> =>
  api
    .post(CHANGE_PASSWORD, opts, toSkipErrorHandlingConfig())
    .catch(handlePasswordChangeError)
    .then(pipeData(() => undefined));

export const getDocs = (api: Client): Promise<ApiDocs> =>
  api
    .get(DOCS)
    .then(response => response.data.contractDocuments)
    .catch(handleAccountApiError);

type ChangePwOpts = {
  newPassword: string;
  oldPassword: string;
};

type FetchUserExistsOpts =
  | string // string will result in being treating as an email
  | { email: string }
  | { username: string };

export {
  SetNewUserPasswordErrorCode,
  setPasswordForNewUser,
  toSetNewUserPasswordErrorCodeOrDefault,
};
