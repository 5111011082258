import type { Verification } from '@ecomm/checkout/models';

export enum ActionTypes {
  VerificationSetSegment = 'ecomm/checkout/VERIFICATION_SET_SEGMENT',
  VerificationSetId = 'ecomm/checkout/VERIFICATION_SET_ID',
}

enum Setting {
  id = 'ecomm/checkout/verification/id',
  segment = 'ecomm/checkout/verification/segment',
}

const getStorage = () => window.localStorage;

export const persistId = (value: string, storage = getStorage()) =>
  storage && storage.setItem(Setting.id, value);

export const getPersistedId = (storage = getStorage()): string => {
  if (storage) {
    return storage.getItem(Setting.id) || '';
  }

  return '';
};

export const persistSegment = (value: string, storage = getStorage()) =>
  storage && storage.setItem(Setting.segment, value);

export const getPersistedSegment = (storage = getStorage()): string => {
  if (storage) {
    return storage.getItem(Setting.segment) || '';
  }

  return '';
};

const getDefaultState = () => ({ id: getPersistedId(), segment: getPersistedSegment() });

const reducer = (state: Verification = getDefaultState(), action: Action) => {
  switch (action.type) {
    case ActionTypes.VerificationSetId: {
      const { id } = action.payload;

      persistId(id);

      return {
        ...state,
        id,
      };
    }

    case ActionTypes.VerificationSetSegment: {
      const { segment } = action.payload;

      persistSegment(segment);

      return {
        ...state,
        segment,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  verification: Verification;
};

export const verificationSetId = (id: string) =>
  <const>{
    type: ActionTypes.VerificationSetId,
    payload: { id },
  };

export const verificationSetSegment = (segment: string) =>
  <const>{
    type: ActionTypes.VerificationSetSegment,
    payload: { segment },
  };

export type VerificationSetIdAction = ReturnType<typeof verificationSetId>;
export type VerificationSetSegmentAction = ReturnType<typeof verificationSetSegment>;

type Action = VerificationSetIdAction | VerificationSetSegmentAction;
