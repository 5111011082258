import { isBrowser, currentHostname } from '@peloton/browser/identify';
const CI_COOKIE_REGEX = /ci_cookie/;

export type EnvironmentFlags = {
  isProd: boolean;
  isProdTest: boolean;
  isStaging: boolean;
  isStageN: boolean;
  isLocal: boolean;
  isUat: boolean;
  isUatProd: boolean;
  isCI: boolean;
  isTest: boolean;
};

export const isProdEnv = (appName: string, hostname = currentHostname): boolean =>
  hostname.startsWith(`${appName}.`) && !isUatEnv(appName, hostname);

export const isProdTestEnv = (appName: string, hostname = currentHostname): boolean => {
  if (appName === 'www') {
    return (
      hostname.includes(`next-www-ssr.prod-test`) ||
      hostname.includes(`${appName}.prod-test`) ||
      hostname.includes(`--prod-${appName}.test`)
    );
  }

  return (
    hostname.includes(`${appName}.prod-test`) ||
    hostname.includes(`--prod-${appName}.test`)
  );
};

export const isStagingEnv = (hostname = currentHostname): boolean =>
  hostname.startsWith('qa1-') ||
  hostname.startsWith('qa2-') ||
  hostname.includes('--lit');

export const isStageNEnv = (hostname = currentHostname): boolean => {
  const regExMatch = /--api-core-(.*)--stage/.exec(hostname);
  return regExMatch && regExMatch[1] ? true : false;
};

export const isUatEnv = (appName: string, hostname = currentHostname): boolean =>
  !isStagingEnv(hostname) &&
  !isUatProdEnv(hostname) &&
  !isProdTestEnv(appName, hostname) &&
  (hostname.startsWith('uat-') ||
    hostname.includes('.uat.') ||
    hostname.includes('.secret.') ||
    hostname.includes('.test.') ||
    hostname.includes('.xtest.'));

export const isDeployPreview = (hostname = currentHostname): boolean =>
  hostname.startsWith('deploy-preview');

export const isUatProdEnv = (hostname = currentHostname): boolean =>
  hostname.startsWith('uat-prod-');

export const isLocalEnv = (hostname = currentHostname): boolean =>
  hostname.startsWith('local-');

export const isCIEnv = () => CI_COOKIE_REGEX.test(isBrowser ? document.cookie : '');

export const isTestEnv = (hostname = currentHostname): boolean =>
  process.env.NODE_ENV === 'test' || hostname.includes('chromatic');

export const isStoryEnv = (hostname = currentHostname): boolean =>
  hostname.includes('chromatic') ||
  hostname.includes('uikit') ||
  process.env.STORYBOOK_IS_STORYBOOK === 'true';

export const isAdminEnv = (hostname = currentHostname): boolean =>
  hostname.includes('admin');

export const isUatProdBackendEnv = (hostname = currentHostname): boolean =>
  hostname.includes('--api');
