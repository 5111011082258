import type { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import React from 'react';

type ContextType = {
  client: ApolloClient<NormalizedCacheObject>;
  previewCopyLoaded: boolean;
  loadingFinished?: boolean;
};

const ContentfulContext = React.createContext<ContextType | undefined>(undefined);

export default ContentfulContext;
