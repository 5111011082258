import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';
import React from 'react';
import { CodeOwnerErrorWrapper } from './CodeOwnerErrorBoundary';
import type { ErrorProperties, ReportError } from './ErrorReporter';

type Props = {
  reportError?: ReportError;
  errorContext?: ErrorProperties;
  renderError: (props: RenderProps) => JSX.Element;
  children: React.ReactNode;
};

export type RenderProps = {
  error: Error | undefined;
};

type State = RenderProps;

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: undefined };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorProps = {
      ...this.props.errorContext,
      tags: { ...this.props.errorContext?.tags },
      extra: { ...this.props.errorContext?.extra, ...errorInfo },
    };

    if (error instanceof CodeOwnerErrorWrapper) {
      const codeownerErrorWrapper = error as CodeOwnerErrorWrapper;
      errorProps.tags.codeOwner = codeownerErrorWrapper.codeOwner;
      errorProps.tags.codeOwnerFilePath = codeownerErrorWrapper.firstTraceFilePath;
    } else if (error instanceof GraphQLError || error instanceof ApolloError) {
      errorProps.tags.lib = 'apollo';
    }

    this.props.reportError?.(error, errorProps);
  }

  render() {
    const { error } = this.state;
    const { children, renderError } = this.props;

    return error ? renderError({ error }) : children;
  }
}
