import { createClientHelpers, createClients } from './client';

// TODO: Grab env vars from @peloton/appConfig
const clientParams = {
  space: '6jnflt57iyzx',
  environment: 'master',
  accessToken: process.env.CONTENTFUL_WWW_PROD_TOKEN as string,
  previewAccessToken: process.env.CONTENTFUL_WWW_PREVIEW_TOKEN,
};

const { client, previewClient } = createClients(clientParams);

const structuredContentClientParams = {
  ...clientParams,
  environment: 'structured-content',
};

const {
  client: structuredContentClient,
  previewClient: structuredContentPreviewClient,
} = createClients(structuredContentClientParams);

const { getClient } = createClientHelpers(client, previewClient);
const { getClient: getStructuredContentClient } = createClientHelpers(
  structuredContentClient,
  structuredContentPreviewClient,
);

export {
  getClient,
  getStructuredContentClient,
  client,
  previewClient,
  structuredContentClient,
  structuredContentPreviewClient,
};

export default client;
